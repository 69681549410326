var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":"mdi-monitor-dashboard","title":"Painel de Controle"}},[_c('emc-loader-progress',{attrs:{"show":_vm.progress}}),_c('div',{staticClass:"text-center"},[_c('v-row',[(_vm.showCard.availability)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('sys-painel-time-table',{attrs:{"title":"Disponibilidade","data":_vm.data.availability,"color":"teal"}},[_c('template',{slot:"subtitle"},[_c('span',[_vm._v("Prazo: "),_c('strong',[_vm._v(_vm._s(_vm._f("date")(_vm.workTime.availability_start)))]),_vm._v(" a "),_c('strong',[_vm._v(_vm._s(_vm._f("date")(_vm.workTime.availability_end)))])])]),_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-account-outline")])],1)]}}],null,false,3296555324)},[_c('span',[_vm._v("Relatório Resumido")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(10)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document")])],1)]}}],null,false,653724494)},[_c('span',[_vm._v("Relatório Detalhado")])])],1)],2)],1):_vm._e(),(_vm.showCard.indication)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('sys-painel-time-table',{attrs:{"title":"Atribuição de Aulas","data":_vm.data.indication,"color":"indigo"}},[_c('template',{slot:"subtitle"},[_c('span',[_vm._v("Prazo: "),_c('strong',[_vm._v(_vm._s(_vm._f("date")(_vm.workTime.indication_start)))]),_vm._v(" a "),_c('strong',[_vm._v(_vm._s(_vm._f("date")(_vm.workTime.indication_end)))])])]),_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(3)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,false,843141527)},[_c('span',[_vm._v(" Relatório Completo ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(19)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-cash-outline")])],1)]}}],null,false,3996889498)},[_c('span',[_vm._v(" Relatório Resumido ")])])],1)],2)],1):_vm._e(),(_vm.showCard.timeTable)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('sys-painel-time-table',{attrs:{"title":"Horário","subTitle":"Elaboração de Horário","data":_vm.data.timeTable,"color":"success"}},[_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(12)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,false,3970679111)},[_c('span',[_vm._v(" Relatório")])])],1)],2)],1):_vm._e(),(_vm.showCard.ambiences)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('sys-painel-time-table',{attrs:{"title":"Ambientes","subTitle":"Alocação de Ambientes","data":_vm.data.ambiences,"color":"purple"}},[_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(25)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,false,593218915)},[_c('span',[_vm._v(" Relatório")])])],1)],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }