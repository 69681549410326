import { mapActions } from 'vuex'
import state from '../../../auth/store/state'
import  { myMixin } from '../../../../mixins';

export default {
  mixins: [myMixin],
  data: ()=>({
    progress: true,
    showCard: {
      availability: true,
      indication: true,
      timeTable: true,
      ambiences: true,
    },
    workTime: state.acesso.periodo,
    data: {
      availability: {
        total: 0,
        percent: 0,
        completed: 0
      },
      indication: {
        total: 0,
        percent: 0,
        completed: 0
      },
      timeTable: {
        total: 0,
        percent: 0,
        completed: 0
      },
      ambiences: {
        total: 0,
        percent: 0,
        completed: 0
      },
    },
  }),

  mounted(){
    
    if(this.showCard.availability){
      this.getDataAvailability();
    }

    if(this.showCard.indication){
      this.getDataIndication();
    }

    if(this.showCard.timeTable){
      this.getDataTimeTable();
    }

    if(this.showCard.ambiences){
      this.getDataAmbiences();
    }
    
  },

  methods:  {
    ...mapActions('controlPanel', [
        'ActionFindIndicatorAvailabilities', 
        'ActionFindIndicatorIndications', 
        'ActionFindIndicatorTimeTable',
        'ActionFindIndicatorAmbiences',
      ]),

    getDataAvailability(){
      
      var payload = {
        work_time_id: state.acesso.periodo.id
      };
      
      this.ActionFindIndicatorAvailabilities(payload)
        .then((res) => {
            this.data.availability = res.data;
        })
        .finally(() => {
            this.progress = false;
      });
     
    },

    getDataIndication(){

      var payload = {
        work_time_id: state.acesso.periodo.id
      };
      
      this.ActionFindIndicatorIndications(payload)
        .then((res) => {
            this.data.indication = res.data;
        })
        .finally(() => {
            this.progress = false;
      });
    },

    getDataTimeTable(){
      
      var payload = {
        work_time_id: state.acesso.periodo.id
      };
      
      this.ActionFindIndicatorTimeTable(payload)
        .then((res) => {
            this.data.timeTable = res.data;
        })
        .finally(() => {
            this.progress = false;
      });
    },

    getDataAmbiences(){
      
      var payload = {
        work_time_id: state.acesso.periodo.id
      };
      
      this.ActionFindIndicatorAmbiences(payload)
        .then((res) => {
            this.data.ambiences = res.data;
        })
        .finally(() => {
            this.progress = false;
      });
    },

  },
}